import {
  HMSReactiveStore,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectPeers,
  selectIsConnectedToRoom,
  selectVideoTrackByID,
} from "@100mslive/hms-video-store";

// Initialize HMS Store SDK
const hmsManager = new HMSReactiveStore();
hmsManager.triggerOnSubscribe();
const hmsStore = hmsManager.getStore();
const hmsActions = hmsManager.getActions();

// Example initialization (assuming `HMSRecordingState` is correctly imported and initialized)

// HTML elements
const form = document.getElementById("join");
const joinBtn = document.getElementById("join-btn");
const conference = document.getElementById("conference");
const peersContainer = document.getElementById("peers-container");
const leaveBtn = document.getElementById("leave-btn");
const muteAudio = document.getElementById("mute-aud");
const muteVideo = document.getElementById("mute-vid");
const controls = document.getElementById("controls");
const shareScreenBtn = document.getElementById("share-screen-btn");
const questionanswerbtn = document.getElementById("question-student-container");
const questionstudent = document.getElementById("question-student");

// store peer IDs already rendered to avoid re-render on mute/unmute
const renderedPeerIDs = new Set();

// Screen Sharing
shareScreenBtn.onclick = async () => {
  try {
    await hmsActions.setScreenShareEnabled(true);
  } catch (error) {
    // an error will be thrown if user didn't give access to share screen
  }
};

// Joining a Room
// joinBtn.onclick = async () => {
//   const roomCode = document.getElementById("room-code").value;
//   const userId = document.getElementById("name").value;
//   const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode });

//   hmsActions.join({
//       userId,
//       authToken
//   });
// };

document.addEventListener("DOMContentLoaded", async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const roomCode = urlParams.get("roomId");
    const teacherId = urlParams.get("teacherId");
    const sessionId = urlParams.get("sessionId");
    const userName = urlParams.get("userName");

    console.log("User:", userId);
    console.log("Room Code:", roomCode);
    console.log("Teacher ID:", teacherId);
    console.log("Session ID:", sessionId);

    // Determine role
    const role = teacherId ? "Teacher" : "User";
    console.log(role);

    // Fetch auth token and join the session
    const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode });
    console.log("Auth Token:", authToken);

    await hmsActions.join({
      userId,
      authToken,
      userName,
      teacher_id: teacherId,
      session_id: sessionId,
      autoManageVideo: true,
      alwaysRequestPermissions: true,
      autoVideoSubscribe: true,
    });

    console.log("Joined the session successfully!");

    // Cache DOM elements
    const elements = {
      conferenceTitle: document.getElementById("conference-title"),
      start: document.getElementById("start"),
      end: document.getElementById("end"),
      pdflist: document.getElementById("pdf-list"),
      userslist: document.getElementById("users-list"),
      allparts: document.getElementById("all-parts"),
      allpartss: document.getElementById("all-partss"),
      questionContainer: document.getElementById("question-container"),
      fetchQuestionWithAnswerDiv: document.getElementById("fatchquestion"),
      questionanswertitle: document.getElementById("questionanswer"),
      questiontitle: document.getElementById("questiontitle"),
      truefules: document.getElementById("truefules"),
      close: document.getElementById("close"),
    };

    // Clear question container
    elements.questionContainer.innerHTML = "";

    // Helper function to fetch data
    const fetchData = async (url, data) => {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      return response.json();
    };

    /*****************************************************************************************************************
     ****************************************** Teacher Live Session Script Start ************************************
     *****************************************************************************************************************/
    const handleTeacherSession = async () => {
      elements.questionContainer.style.display = "block";
      const postData = { teacher_id: teacherId, session_id: sessionId };

      // Fetch teacher session data
      const responseData = await fetchData(
        "https://teacher.success.sa/api/fetch_session_data",
        postData
      );
      console.log("Teacher session data:", responseData);

      // Update UI with session data
      elements.conferenceTitle.innerHTML = responseData.data.session.name;
      elements.allparts.style.display = "block";
      elements.allpartss.style.display = "block";
      elements.fetchQuestionWithAnswerDiv.style.display = "none";
      elements.close.addEventListener("click", () => {
        questionanswerbtn.style.display = "none";
        elements.questionContainer.style.width = "100%";
      });

      // Handle questions
      if (Array.isArray(responseData.data.questions)) {
        responseData.data.questions.forEach((question, index) => {
          // Create the question container
          const questionDiv = document.createElement("div");
          questionDiv.className = "question";
          questionDiv.id = `question-${question.id}`;
          questionDiv.innerHTML = `
            <h4>${index + 1}. ${question.question || "No text available"}
              <div class="btn-anss">
                <button title="Show Answer" class="question-answer-btn" id="show-answer-btn-${
                  question.id
                }"><i class="fa-solid fa-comment-dots"></i></button>
               
                <button title="Send question" class="question-answer-btn" ${
                  question.is_sent ? "disabled" : ""
                } id="send-question-btn-${question.id}">  ${
            question.is_sent
              ? '<i class="fa-solid fa-check"></i>'
              : '<i class="fa-solid fa-paper-plane"></i>'
          }</button>
                <button title="Show Student" class="question-answer-btn" id="send-student-btn-${
                  question.id
                }"><i class="fa-solid fa-eye"></i></button>
              </div>
            </h4>
          `;

          // Create the answers container (initially hidden)
          const questionAnswersDiv = document.createElement("div");
          questionAnswersDiv.className = "question-answer";
          questionAnswersDiv.id = `question-answer-${question.id}`;
          questionAnswersDiv.style.display = "none"; // Initially hidden
          questionAnswersDiv.dataset.questionId = question.id;
          questionAnswersDiv.innerHTML = `<p>${
            question.answer.answer || "No answer available"
          }</p>`;

          // Append the question and answers to the container
          elements.questionContainer.appendChild(questionDiv);
          elements.questionContainer.appendChild(questionAnswersDiv);

          // Show answer functionality
          const showAnswerBtn = document.getElementById(
            `show-answer-btn-${question.id}`
          );
          showAnswerBtn.addEventListener("click", () => {
            questionAnswersDiv.style.display =
              questionAnswersDiv.style.display === "none" ? "block" : "none";
          });

          // Send question functionality
          const sendQuestionBtn = document.getElementById(
            `send-question-btn-${question.id}`
          );

          sendQuestionBtn.addEventListener("click", async () => {
            try {
              const sendQuestionResponse = await fetch(
                "https://teacher.success.sa/api/send_live_question",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    session_id: sessionId,
                    question_id: question.id, // Pass the question id
                  }),
                }
              );

              if (!sendQuestionResponse.ok) {
                throw new Error(
                  `Error posting question: ${sendQuestionResponse.statusText}`
                );
              }

              const sendQuestionData = await sendQuestionResponse.json();
              console.log("Question sent successfully:", sendQuestionData);

              // Disable the button to prevent sending the same question multiple times
              sendQuestionBtn.disabled = true;
              sendQuestionBtn.innerHTML = `<i class="fa-solid fa-check"></i>`;
            } catch (error) {
              console.error("Error sending question:", error);
            }
          });

          // Show Student functionality
          const sendStudentBtn = document.getElementById(
            `send-student-btn-${question.id}`
          );

          sendStudentBtn.addEventListener("click", async () => {
            try {
              const sendStudentResponse = await fetch(
                "https://teacher.success.sa/api/fetch-question-student-answer",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    session_id: sessionId,
                    question_id: question.id, // Pass the question id
                  }),
                }
              );

              if (!sendStudentResponse.ok) {
                throw new Error(
                  `Error fetching student answer: ${sendStudentResponse.statusText}`
                );
              }
              questionanswerbtn.style.display = "block";
              elements.questionContainer.style.width = "50%";

              const sendStudentData = await sendStudentResponse.json();
              console.log(
                "Student answer fetched successfully:",
                sendStudentData
              );

              // Assuming `sendStudentData` contains an array of student answers
              appendStudentAnswersToTable(sendStudentData);
            } catch (error) {
              console.error("Error fetching student answer:", error);
            }
          });

          // Handle answers for the question
          if (Array.isArray(question.answers)) {
            question.answers.forEach((answer) => {
              const answerDiv = document.createElement("div");
              answerDiv.className = "answer";
              answerDiv.dataset.answerId = answer.id;
              answerDiv.innerHTML = `<p>${
                answer.description || "No description available"
              }</p>`;

              // Append the answer div to the question div
              questionDiv.appendChild(answerDiv);

              // Handle answer click event
              answerDiv.addEventListener("click", async () => {
                try {
                  const sendAnswerResponse = await fetch(
                    "https://teacher.success.sa/api/send_answer",
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        session_id: sessionId,
                        question_id: question.id, // Pass the question id
                        answer_id: answer.id, // Pass the answer id
                        user_id: userId, // Pass the user id
                      }),
                    }
                  );

                  if (!sendAnswerResponse.ok) {
                    throw new Error(
                      `Error posting answer: ${sendAnswerResponse.statusText}`
                    );
                  }

                  const sendAnswerData = await sendAnswerResponse.json();
                  console.log("Answer sent successfully:", sendAnswerData);

                  // Disable further clicks on the answer after sending
                  answerDiv.style.pointerEvents = "none";
                } catch (error) {
                  console.error("Error sending answer:", error);
                }
              });
            });
          }
        });
      } else {
        console.log("No questions found or questions are not in array format.");
      }

      // Function to append student answers to the table body
      function appendStudentAnswersToTable(studentAnswers) {
        const tbody = document.getElementById("student-answers-tbody"); // Replace with your tbody element ID
        tbody.innerHTML = ""; // Clear previous answers if necessary

        studentAnswers.data.forEach((data) => {
          const row = document.createElement("tr");
          row.innerHTML = `
            <td>${data.name || "No name available"}</td>
            <td>${data.answer.answer || "No answer available"}</td>
            <td>${
              data.answer.is_correct == 1
                ? `<i class="fa-regular fa-circle-check"></i>`
                : `<i class="fa-regular fa-circle-xmark"></i>`
            }</td>
          `;
          tbody.appendChild(row);
        });
      }

      // Handle users
      if (Array.isArray(responseData.data.users)) {
        elements.userslist.innerHTML = "";
        responseData.data.users.forEach((user) => {
          const listItem = document.createElement("li");
          const image = document.createElement("img");
          image.className = "user-image";
          image.src =
            user.image ||
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
          listItem.appendChild(image);
          listItem.appendChild(document.createTextNode(user.name));
          elements.userslist.appendChild(listItem);
        });
      }

      // Handle PDFs
      if (Array.isArray(responseData.data.attachments)) {
        responseData.data.attachments.forEach((pdf) => {
          const listItem = document.createElement("li");
          const icon = document.createElement("i");
          icon.className = "fa-regular fa-file-pdf";
          const link = document.createElement("a");
          link.href = pdf.pdf;
          link.textContent = pdf.title;
          link.target = "_blank";

          listItem.appendChild(icon);
          listItem.appendChild(link);
          elements.pdflist.appendChild(listItem);
        });
      }

      // Initialize countdown
      const { start_time, end_time, start_date, end_date } =
        responseData.data.session;
      initializeCountdown(start_time, end_time, start_date, end_date);
    };

    /*****************************************************************************************************************
     ****************************************** Teacher Live Session Script End **************************************
     *****************************************************************************************************************/

    /*****************************************************************************************************************
     ****************************************** Student Live Session Script Start *************************************
     *****************************************************************************************************************/
    // Function to handle student session
    const handleStudentSession = async () => {
      // Display relevant sections and hide others
      elements.fetchQuestionWithAnswerDiv.style.display = "block";
      elements.allparts.style.display = "none";
      elements.allpartss.style.display = "none";

      const postData = { user_id: userId, session_id: sessionId };

      // Fetch student session data
      const responseData = await fetchData(
        "https://teacher.success.sa/api/fetch_student_session_data",
        postData
      );
      console.log("Student session data:", responseData);

      elements.conferenceTitle.innerHTML = responseData.data.session.name;
      elements.questionanswertitle.style.display = "block";
      elements.fetchQuestionWithAnswerDiv.style.display = "block";
      elements.questionContainer.style.display = "none";
      elements.questiontitle.style.display = "none";
      elements.truefules.style.display = "none";

      // Handle questions and answers
      if (Array.isArray(responseData.data.questions)) {
        responseData.data.questions.forEach((question) => {
          // Check if the question already exists
          if (!document.querySelector(`[data-question-id="${question.id}"]`)) {
            // console.log("Questions if condition:", responseData.data.questions);
            const StudentquestionDiv = document.createElement("div");
            StudentquestionDiv.className = "question";
            StudentquestionDiv.innerHTML = `<h4>${
              question.question || "No text available"
            } ${
              question.answer.is_correct == 1
                ? `<i class="fa-regular fa-circle-check"></i>`
                : `<i class="fa-regular fa-circle-xmark"></i>`
            }</h4>`;
            StudentquestionDiv.dataset.questionId = question.id;

            elements.fetchQuestionWithAnswerDiv.appendChild(StudentquestionDiv);

            // if (Array.isArray(question.answers)) {
            console.log("Answers if condition:", question.answer);
            var answer = question.answer;
            // question.answers.forEach((answer) => {
            if (!document.querySelector(`#answer${answer.id}`)) {
              const answerDiv = document.createElement("div");
              answerDiv.className = "answer";
              answerDiv.innerHTML = `<p  id="answer${answer.id}">${answer.description}</p>`;
              answerDiv.dataset.answerId = answer.id;

              StudentquestionDiv.appendChild(answerDiv);
            }
            // });
            // }
          }
        });

        // Set up Pusher for real-time updates
        Pusher.logToConsole = true;
        const pusher = new Pusher("b9b3abf7537cef44e08f", { cluster: "eu" });
        const channel = pusher.subscribe(
          `session${responseData.data.session.slug}`
        );

        channel.bind(`question${responseData.data.session.slug}`, (res) => {
          // Display real-time updates
          elements.truefules.style.display = "block";

          const questionWithAnswerDiv = document.createElement("div");
          questionWithAnswerDiv.className = "questionfetch";
          elements.truefules.innerHTML = "";

          // Append the new element
          elements.truefules.appendChild(questionWithAnswerDiv);

          const questionHTML = `<h4>${res.question.question}</h4>`;
          const answersContainer = document.createElement("div");
          answersContainer.className = "answers-container";
          const answersListDiv = document.createElement("div");
          answersListDiv.className = "answers-list";

          res.answers.forEach(({ answer, id, is_correct }) => {
            if (!document.querySelector(`#answer${id}`)) {
              const answerDiv = document.createElement("div");
              answerDiv.className = "answer";
              answerDiv.innerHTML = `<p class="answer-stu" id="answer${id}">
                ${answer}
                <i class="${
                  is_correct
                    ? "fa-solid fa-check"
                    : "fa-regular fa-circle-xmark"
                } icon-behind"></i>
              </p>`;
              answerDiv.dataset.answerId = id;

              answerDiv.addEventListener("click", async () => {
                elements.truefules.style.display = "none";

                try {
                  const answerRes = await fetch(
                    "https://teacher.success.sa/api/send_live_answer",
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        session_id: sessionId,
                        user_id: userId,
                        answer_id: id,
                      }),
                    }
                  );

                  const answerResData = await answerRes.json();
                  console.log("Answer result:", answerResData);

                  const resultDiv = document.createElement("div");
                  resultDiv.className = "questionfetch";
                  resultDiv.innerHTML = `
                    <div class="answer-result">
                      <h4> <i class="${
                        answerResData.data.is_correct === 1
                          ? "fa-solid fa-check"
                          : "fa-regular fa-circle-xmark"
                      } icon-behind"></i>${res.question.question}</h4>
                      <p>
                        ${answerResData.data.description}
                       
                      </p>
                    </div>
                  `;

                  elements.fetchQuestionWithAnswerDiv.appendChild(resultDiv);
                } catch (error) {
                  console.error("Error sending live answer:", error);
                }
              });

              answersListDiv.appendChild(answerDiv);
            }
          });

          answersContainer.style.display = "flex";
          answersContainer.style.flexDirection = "row";
          answersContainer.appendChild(answersListDiv);
          questionWithAnswerDiv.innerHTML = questionHTML;
          questionWithAnswerDiv.appendChild(answersContainer);
        });
      } else {
        console.log("No questions found or questions are not in array format.");
      }
      // Function to render or clear screen sharing
      function renderScreenShare(screenTrack) {
        const screenShareContainer = document.getElementById(
          "screen-share-container"
        );
        if (screenTrack && screenTrack.enabled) {
          const screenShareVideo = createElementWithClass(
            "video",
            "screen-share-video"
          );
          screenShareVideo.autoplay = true;
          screenShareVideo.playsinline = true;
          hmsActions.attachVideo(screenTrack.id, screenShareVideo);

          screenShareContainer.innerHTML = "";
          screenShareContainer.append(screenShareVideo);
        } else {
          // Clear screen share container when screen sharing stops
          screenShareContainer.innerHTML = "Screen sharing has stopped.";
        }
      }
    };

    /*******************************************************************************************************************
     ****************************************** Student Live Session Script End ***************************************
     *******************************************************************************************************************/

    // Execute appropriate handler based on role
    if (teacherId) {
      await handleTeacherSession();
    } else {
      await handleStudentSession();
    }
  } catch (error) {
    console.error("Error initializing session:", error);
  }
});

async function leaveRoom() {
  await hmsActions.leave();
  peersContainer.innerHTML = "";

  // Redirect to the new link
  window.location.href =
    "https://teacher.success.sa/subscription_sessions?tab=mycourses";
}

// Cleanup if user refreshes the tab or navigates away
window.onunload = window.onbeforeunload = leaveRoom;
leaveBtn.onclick = leaveRoom;

// Helper function to create html elements
function createElementWithClass(tag, className) {
  const newElement = document.createElement(tag);
  newElement.className = className;
  return newElement;
}

// Render a single peer video
function renderPeer(peer) {
  const peerTileDiv = createElementWithClass("div", "peer-tile");
  const videoElement = createElementWithClass("video", "peer-video");
  const peerTileName = createElementWithClass("div", "peer-name");
  videoElement.autoplay = true;
  videoElement.muted = true;
  videoElement.playsinline = true;
  peerTileName.textContent = peer.name;

  hmsStore.subscribe((track) => {
    if (!track) {
      return;
    }
    if (track.enabled) {
      hmsActions.attachVideo(track.id, videoElement);
    } else {
      hmsActions.detachVideo(track.id, videoElement);
    }
  }, selectVideoTrackByID(peer.videoTrack));

  peerTileDiv.append(videoElement);
  peerTileDiv.append(peerTileName);

  renderedPeerIDs.add(peer.id);
  return peerTileDiv;
}

// Display a tile for each peer in the peer list
function renderPeers() {
  const peers = hmsStore.getState(selectPeers);

  peers.forEach((peer) => {
    if (!renderedPeerIDs.has(peer.id) && peer.videoTrack) {
      peersContainer.append(renderPeer(peer));
    }
  });
}

// Reactive state - renderPeers is called whenever there is a change in the peer-list
hmsStore.subscribe(renderPeers, selectPeers);

// Mute and unmute audio
muteAudio.onclick = async () => {
  const audioEnabled = !hmsStore.getState(selectIsLocalAudioEnabled);
  await hmsActions.setLocalAudioEnabled(audioEnabled);
  muteAudio.textContent = audioEnabled ? "Mute" : "Unmute";
};

// Mute and unmute video
muteVideo.onclick = async () => {
  const videoEnabled = !hmsStore.getState(selectIsLocalVideoEnabled);
  await hmsActions.setLocalVideoEnabled(videoEnabled);
  muteVideo.textContent = videoEnabled ? "Hide" : "Unhide";
};

// Showing the required elements on connection/disconnection
function onConnection(isConnected) {
  if (isConnected) {
    form.classList.add("hide");
    conference.classList.remove("hide");
    leaveBtn.classList.remove("hide");
    controls.classList.remove("hide");
  } else {
    form.classList.remove("hide");
    conference.classList.add("hide");
    leaveBtn.classList.add("hide");
    controls.classList.add("hide");
  }
}

// Listen to the connection state
hmsStore.subscribe(onConnection, selectIsConnectedToRoom);
document.addEventListener("DOMContentLoaded", function () {
  // Show the loader when the page starts loading
  const loader = document.getElementById("loader");
  loader.style.display = "block";

  // Simulate loading time for demo purposes
  setTimeout(function () {
    // Hide the loader when content is loaded
    loader.style.display = "none";
  }, 3000); // Adjust this time based on your actual content loading time

  // Additional code to handle actual content loading events
  // If you have async operations, hide the loader when they complete
});
